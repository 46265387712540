import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import equipementService from '../../services/equipements';
import noteService from '../../services/notes';
import {
  EquipementState,
  EquipementSortValues,
  EquipementPayload,
  EditedEquipementData,
  ClosedReopenedEquipementData,
  Note,
  EquipementFilterValues,
  QuestionsInstrum,
  ReponsesPayload,
} from '../types';
import { notify } from './notificationSlice';
import { History } from 'history';
import { getErrorMsg } from '../../utils/helperFuncs';
import { fetchPostes, setFetchPostesError, setPostes } from './postesSlice';
import posteService from '../../services/postes';
import roomService from '../../services/rooms';
import { setRooms } from './roomsSlice';
import questionsIntrumService from '../../services/questionsInstrum';
import usageService from '../../services/usages';
import { setUsages } from './usagesSlice';
import { setQuestionsInstrums } from './questionsInstrumSlice';
import voie2mesureService from '../../services/voie2mesures';
import { fetchVoie2mesures, setVoie2mesures } from './voie2mesuresSlice';
import { dataCollectionPhase } from 'typeorm-model-generator/dist/src/Engine';
import { V4MAPPED } from 'dns';
//import { dataCollectionPhase } from 'typeorm-model-generator/dist/src/Engine';

interface InitialEquipementState {
  equipements: { [siteId: string]: EquipementState[] };
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoadingEquipement: boolean;
  submitErrorEquipement: string | null;
  sortBy: EquipementSortValues;
  filterBy: EquipementFilterValues;
  grep: string;
}

const initialState: InitialEquipementState = {
  equipements: {},
  fetchLoading: false,
  fetchError: null,
  submitLoadingEquipement: false,
  submitErrorEquipement: null,
  sortBy: 'default'/*'newest'*/,
  filterBy: /*'open'*/'all',
  grep: '0',
};

const equipementsSlice = createSlice({
  name: 'equipements',
  initialState,
  reducers: {
    setEquipements: (
      state,
      action: PayloadAction<{ equipements: EquipementState[]; siteId: string }>
      ) => {
      console.log("setEquipements... SLICE");
      state.equipements[action.payload.siteId] = action.payload.equipements;
      state.grep = '0'
      state.fetchLoading = false;
      state.fetchError = null;
    },
/*    setEquipements: (
      state,
      action: PayloadAction<{ equipements: EquipementState[]; siteId: string }>
    ) => {
      state.equipements[action.payload.siteId] = action.payload.equipements;
      state.fetchLoading = false;
      state.fetchError = null;
    },*/
    setEquipementsBySitesSn: (
      state,
      action: PayloadAction<{ equipements: EquipementState[]; siteSn: string }>
    ) => {
      console.log("setEquipementsBySitesSn... SLICE");
      state.equipements[action.payload.siteSn] = action.payload.equipements;
      state.fetchLoading = false;
      state.fetchError = null;
      state.grep = '0'
      //state.equipements[action.payload.siteSn].grep = "0"
    },
    addEquipement: (    // TODO: change to SITE_SN, not SITE_ID
      state,
      action: PayloadAction<{ equipement: EquipementState; siteId: string }>
    ) => {
      if (action.payload.equipement.sn_site in state.equipements) {
        state.equipements[action.payload.equipement.sn_site].push(action.payload.equipement);
      } else {
        state.equipements[action.payload.siteId] = [action.payload.equipement];
      }
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
/*      if (action.payload.siteId in state.equipements) {
        state.equipements[action.payload.siteId].push(action.payload.equipement);
      } else {
        state.equipements[action.payload.siteId] = [action.payload.equipement];
      }
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;*/
    },
    oublierLaVoie: (     
      state,
      action: PayloadAction<{
        data: EquipementState;
        voieId: string;
        siteId: string;
      }>
    ) => {
      console.log("oublierLaVoie... sn_site : "+action.payload.data.sn_site);
      if (state.equipements !== undefined) {
//        state.equipements[action.payload.data.sn_site] = state.equipements[action.payload.data.sn_site].filter((eq) => eq.voies2mesures.filter((vm) => vm.id !== Number(action.payload.voieId)))
        state.equipements[action.payload.data.sn_site] = state.equipements[action.payload.data.sn_site].filter((e) => e.id !== action.payload.data.id) // = state.equipements[action.payload.data.sn_site].filter((eq) => eq.voies2mesures.filter((vm) => vm.id !== Number(action.payload.voieId)))
        state.equipements[action.payload.data.sn_site].push(action.payload.data)
      }
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },
    updateRepAuxQuestions: (
      state,
      action: PayloadAction<{
        data: EquipementState;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      console.log("updateRepAuxQuestions... sn_site : "+action.payload.data.sn_site);
      state.equipements[action.payload.data.sn_site] = state.equipements[
        //  action.payload.data.sn_site
          action.payload.data.sn_site
        ].map((b) =>
          b.id === action.payload.data.id ? { ...b, ...action.payload.data } : b
        );
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },
    updateEquipement: (     // TEST : on supprime l'appareil, et on le rajoute, pour forcer le refresh de l'interface
      state,
      action: PayloadAction<{
        data: EquipementState;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      console.log("updateEquipement... sn_site : "+action.payload.data.sn_site);
      if (action.payload.data.sn_site in state.equipements) {
        state.equipements[action.payload.data.sn_site] = state.equipements[
          action.payload.data.sn_site
        ].filter((b) => b.id !== action.payload.equipementId);
        state.equipements[action.payload.data.sn_site].push(action.payload.data);
      }
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },
    /*: (
      state,
      action: PayloadAction<{
        data: EquipementState;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      console.log("updateEquipement... sn_site : "+action.payload.data.sn_site);
//      state.equipements[action.payload.siteId] = state.equipements[
    //  state.equipements[action.payload.data.sn_site] = state.equipements[
      state.equipements[action.payload.data.sn_site] = state.equipements[
      //  action.payload.data.sn_site
        action.payload.data.sn_site
      ].map((b) =>
        b.id === action.payload.data.id ? { ...b, ...action.payload.data } : b
//        b.id === action.payload.equipementId ? { ...b, ...action.payload.data } : b
      );
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },*/
/*    updateEquipement: (
      state,
      action: PayloadAction<{
        data: EditedEquipementData;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      console.log("updateEquipement (slice)");
      state.equipements[action.payload.siteId] = state.equipements[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.equipementId ? { ...b, ...action.payload.data } : b
      );
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },*/
    //@ts-ignore
    updateEquipements: (    // pas compatible avec "initialEquipementState" type de base du slice...
      state,
      action: PayloadAction<{
        data: EditedEquipementData;
        equipementId: string;
        siteId: string;
      }>[]
    ) => {
      console.log("updateEquipementssss... SLICE");
      action.map((a) =>
  //      state.equipements[action.payload.sn_site] = state.equipements[
        state.equipements[a.payload.data.site_sn] = state.equipements[
          a.payload.data.sn_site
        ].map((b) =>
          b.id === a.payload.data.id ? { ...b, ...a.payload.data } : b
        )
      )
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },
    removeEquipementDB: (
      state,
      action: PayloadAction<{ equipementId: string; siteSn: string; voieId: string }>
    ) => {
      state.equipements[action.payload.siteSn] = state.equipements[
        action.payload.siteSn
      ].filter((b) => b.id !== action.payload.equipementId);
/*      if(action.payload.voieId !== "0" ) {
        state.equipements[action.payload.siteSn] = state.equipements[
          action.payload.siteSn
        ].filter((b) => b.id !== action.payload.equipementId
        && b.voies2mesures.filter((v) => v.id !== Number(action.payload.voieId)));
      } else {
        state.equipements[action.payload.siteSn] = state.equipements[
          action.payload.siteSn
        ].filter((b) => b.id !== action.payload.equipementId);
      }*/
//      ].filter((b) => b.id !== action.payload.equipementId);
/*      state.equipements[action.payload.siteId] = state.equipements[
        action.payload.siteId
      ].filter((b) => b.id !== action.payload.equipementId);*/
    },
    removeEquipement: (
      state,
      action: PayloadAction<{ equipementId: string; siteId: string }>
    ) => {
      state.equipements[action.payload.siteId] = state.equipements[
        action.payload.siteId
      ].filter((b) => b.id !== action.payload.equipementId);
    },
    updateEquipementStatus: (
      state,
      action: PayloadAction<{
        data: ClosedReopenedEquipementData;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      state.equipements[action.payload.siteId] = state.equipements[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.equipementId ? { ...b, ...action.payload.data } : b
      );
      console.log("updateEquipementStatus (slice)");
    },
    addNote: (
      state,
      action: PayloadAction<{ note: Note; equipementId: string; siteId: string }>
    ) => {
/*      state.equipements[action.payload.siteId] = state.equipements[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.equipementId
          ? { ...b, notes: [...b.notes, action.payload.note] }
          : b
      );*/
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = null;
    },
    updateNote: (
      state,
      action: PayloadAction<{
        data: { body: string; date_update: Date };
        noteId: number;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      const equipement = state.equipements[action.payload.siteId].find(
        (b) => b.id === action.payload.equipementId
      );
/*
      if (equipement) {
        const updatedNotes = equipement.notes.map((n) =>
          n.id === action.payload.noteId ? { ...n, ...action.payload.data } : n
        );

        state.equipements[action.payload.siteId] = state.equipements[
          action.payload.siteId
        ].map((b) =>
          b.id === action.payload.equipementId ? { ...b, notes: updatedNotes } : b
        );

        state.submitLoadingEquipement = false;
        state.submitErrorEquipement = null;
      }*/
    },
    removeNote: (
      state,
      action: PayloadAction<{
        noteId: number;
        equipementId: string;
        siteId: string;
      }>
    ) => {
      const equipement = state.equipements[action.payload.siteId].find(
        (b) => b.id === action.payload.equipementId
      );
/*
      if (equipement) {
        const updatedNotes = equipement.notes.filter(
          (n) => n.id !== action.payload.noteId
        );

        state.equipements[action.payload.siteId] = state.equipements[
          action.payload.siteId
        ].map((b) =>
          b.id === action.payload.equipementId ? { ...b, notes: updatedNotes } : b
        );
      }*/
    },
    setFetchEquipementsLoading: (state) => {
      state.fetchLoading = true;
      state.fetchError = null;
    },
    setFetchEquipementsError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
    setSubmitEquipementLoading: (state) => {
      state.submitLoadingEquipement = true;
      state.submitErrorEquipement = null;
    },
    setSubmitEquipementError: (state, action: PayloadAction<string>) => {
      state.submitLoadingEquipement = false;
      state.submitErrorEquipement = action.payload;
    },
    clearSubmitEquipementError: (state) => {
      state.submitErrorEquipement = null;
    },
    sortEquipementsBy: (state, action: PayloadAction<EquipementSortValues>) => {
      console.log("sortEquipementBy "+String(action.payload)+" CALLED");
      state.sortBy = action.payload;
    },
    grepEquipementsBy: (state, action: PayloadAction<EquipementSortValues>) => {
//      console.log("sortEquipementBy "+String(action.payload)+" CALLED");
      state.grep = action.payload;
    },
    filterEquipementsBy: (state, action: PayloadAction<EquipementFilterValues>) => {
      state.filterBy = action.payload;
    },
  },
});

export const {    // pour les MàJ des états et des vues
  setEquipements,
  setEquipementsBySitesSn,
  addEquipement,
  updateEquipement,
  updateEquipements,
  oublierLaVoie,
//  updateReponsesFI,
//  updateEquipementDB,
  removeEquipement,
  removeEquipementDB,
  updateEquipementStatus,
  updateRepAuxQuestions,
  addNote,
  updateNote,
  removeNote,
  setFetchEquipementsLoading,
  setFetchEquipementsError,
  setSubmitEquipementLoading,
  setSubmitEquipementError,
  clearSubmitEquipementError,
  sortEquipementsBy,
  grepEquipementsBy,
  filterEquipementsBy,
} = equipementsSlice.actions;

export const createEquipement = (
  siteId: string,
  equipementId: string,
  equipementData: EquipementPayload/*string*/,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const newEquipement = await equipementService.createEquipement(siteId, /*equipementId, */equipementData);
      dispatch(addEquipement({ equipement: newEquipement, siteId }));
      dispatch(notify('New equipement added!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const createNewEquipementDB = (
  siteId: string,
  equipementData: EquipementPayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const newEquipement = await equipementService.createEquipement(siteId, equipementData);
      dispatch(addEquipement({ equipement: newEquipement, siteId }));
      dispatch(notify('New equipement added!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const createNewEquipement = (
  siteId: string,
  equipementData: EquipementPayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const newEquipement = await equipementService.createEquipement(siteId, equipementData);
      //dispatch(addEquipement({ equipement: newEquipement, siteId }));
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        site_sn,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
	id_circuit,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
      } = newEquipement as EditedEquipementData;

      dispatch(
        addEquipement({
          equipement: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          /*equipementId*/ siteId/*repstr*/,
        })
      );
      dispatch(notify('Nouvel équipement ajouté au logement', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};
export const closeReponsesFI =(
  closeDialog?: () => void
  ): AppThunk => {
  console.log("slice:closeReponsesFI");
  return async (dispatch) => {
    try {
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};
export const majReponsesFI = (
  equipementId: string,
  siteId: string,
  lesreponses: ReponsesPayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("updateReponsesFI");
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const updatedEquipement = await equipementService.updateReponsesFI(equipementId, lesreponses);
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      dispatch(
        updateRepAuxQuestions({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          equipementId, siteId/*repstr*/,
        })
      );
      dispatch(notify('Réponses aux questions mises à jour', 'success'));
      // TODO : dispatch selectEquipementBySiteID ??
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const editEquipementDB = (
  equipementId: string,
  siteId: string,
  equipementData: EquipementPayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("editEquipementDB");
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const updatedEquipement = await equipementService.updateEquipementDB(equipementId, equipementData);
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      dispatch(
        updateEquipement({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          equipementId, siteId,
        })
      );
//      fetchVoie2mesures();
      const updatedEquips = await equipementService.getEquipementsBySiteSn(equipementData.site_sn);
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(equipementData.site_sn)/*updatedEquipement.site_sn*/);//(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify('Infos appareil corrigées !', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};
export const editEquipement = (
  equipementId: string,
  siteId: string,
  equipementData: EquipementPayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("editEquipement");
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const updatedEquipements = await equipementService.updateEquipement(equipementId, equipementData);
      let nbEquip: number;
      updatedEquipements[1] ? nbEquip = 2 : nbEquip = 1; //updateEquipements.length;
      console.log("nb Equipements retournés par l'API: "+nbEquip)
      if(nbEquip > 1) {
        const [{
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          sn_site,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        }] = updatedEquipements as EditedEquipementData[];

  //      fetchVoie2mesures();
            console.log("updatedEquipement ID: "+updatedEquipements[1].id)
            dispatch(updateEquipement({
              data: updatedEquipements[1],
    /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
                str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
              equipementId, siteId,
            }));
            console.log("addEquipement ID: "+updatedEquipements[0].id)
            dispatch(addEquipement({
              equipement: updatedEquipements[0],
    /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
                str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
              siteId,
            }));
      }
      if(nbEquip === 1) {
        const {
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          sn_site,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        } = updatedEquipements[0] as EditedEquipementData;

        dispatch(updateEquipement({
            data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
              str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum, id_circuit },
            equipementId, siteId,
          }));
      }
      const updatedEquips = await equipementService.getEquipementsBySiteSn(updatedEquipements[0].sn_site);
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(equipementData.site_sn)/*updatedEquipement.site_sn*/);//(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify('Appareil mis à jour', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const editEquipementBack = (
  equipementId: string,
  siteId: string,
  equipementData: EquipementPayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("editEquipement");
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const updatedEquipements = await equipementService.updateEquipement(equipementId, equipementData);
      const [{
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      }] = updatedEquipements as EditedEquipementData[];
      
      updatedEquipements.map((e: EditedEquipementData) => 
        dispatch(
        updateEquipement({
          data: e,
/*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
          equipementId, siteId,
        })
      ));
      updatedEquipements.map((e: EditedEquipementData) => console.log("Dispatch update equipement : "+e.id));
      dispatch(notify('Appareil mis à jour', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};
export const deleteEquipement = (
  siteId: string,
  equipementId: string,
  history: History
): AppThunk => {
  return async (dispatch) => {
    try {
      await equipementService.deleteEquipement(siteId, equipementId);
      history.push(`/sites/${siteId}`);
      dispatch(removeEquipement({ equipementId, siteId }));
      dispatch(notify('Appareil supprimé à la date de fin indiquée', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

/* Supprime l'équipement de la base de manière DÉFINITIVE, et déréférence l'appareil sur la voie de mesures associée si elle existe */
export const deleteEquipementDB = (
  siteId: string,
  siteSn: string,
  equipementId: string,
  voieId: number,
//  history: History
): AppThunk => {
  return async (dispatch) => {
    try {
      await equipementService.deleteEquipementDB(siteId, equipementId, String(voieId));
//      history.push(`/sites/${siteId}`);
      dispatch(removeEquipementDB({ equipementId, siteSn, voieId }));
      const updatedEquips = await equipementService.getEquipementsBySiteSn(siteSn);
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(siteSn);/*updatedEquipement.site_sn*///(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify('Appareil supprimé ! (pour toujours)', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const closeReopenEquipement = (
  siteId: string,
  equipementId: string,
  action: 'close' | 'reopen'
): AppThunk => {
  return async (dispatch) => {
    try {
      let returnedData;
      if (action === 'close') {
        returnedData = await equipementService.closeEquipement(siteId, equipementId);
      } else {
        returnedData = await equipementService.reopenEquipement(siteId, equipementId);
      }
      const {
        isResolved,
        closedAt,
        closedBy,
        reopenedAt,
        reopenedBy,
      } = returnedData as ClosedReopenedEquipementData;
      dispatch(
        updateEquipementStatus({
          data: { isResolved, closedAt, closedBy, reopenedAt, reopenedBy },
          equipementId,
          siteId,
        })
      );
      dispatch(
        notify(
          `${action === 'close' ? 'Closed' : 'Re-opened'} the equipement!`,
          'success'
        )
      );
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const editNote = (
  siteId: string,
  equipementId: string,
  noteId: number,
  noteBody: string,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setSubmitEquipementLoading());
      const returnedData = await noteService.editNote(
        siteId,
        noteId,
        noteBody
      );
      const { body, date_update } = returnedData as Note;
      dispatch(
        updateNote({ data: { body, date_update }, noteId, equipementId, siteId })
      );
      dispatch(notify('Updated the note!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const deleteNote = (
  siteId: string,
  equipementId: string,
  noteId: number
): AppThunk => {
  return async (dispatch) => {
    try {
      await noteService.deleteNote(siteId, noteId);
      dispatch(removeNote({ noteId, equipementId, siteId }));
      dispatch(notify('Deleted the note.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const fetchEquipements = (): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setFetchEquipementsLoading());
      const allEquipements = await equipementService.getAllEquipements();
      dispatch(setEquipements(allEquipements));
      dispatch(fetchPostes());
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const fetchEquipementsBySiteId = (siteId: string): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setFetchEquipementsLoading());
      const equipements = await equipementService.getAllEquipements();
      dispatch(setEquipements({ equipements: equipements, siteId }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchEquipementsError(getErrorMsg(e)));
    }
  };
};

export const fetchEquipementsBySitesSn = (siteSn: string): AppThunk => {
  return async (dispatch) => {
    try {//@ts-ignore
      dispatch(setFetchEquipementsLoading());
      const equipements = await equipementService.getEquipementsBySiteSn(siteSn);
      dispatch(setEquipements(equipements));
   //   dispatch(setEquipementsBySitesSn({ equipements: equipements, siteSn }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchEquipementsError(getErrorMsg(e)));
    }
  };
};

export const fetchEquipementsForSiteSn = (
  siteSn: string,
): AppThunk => {
  return async (dispatch) => {
    try {
      //@ts-ignore
      dispatch(setFetchEquipementsLoading());
      const voies2mesures = await voie2mesureService.getSiteVoie2mesures(siteSn);
      dispatch(setVoie2mesures(voies2mesures));
      const postes = await posteService.getPostes();
      dispatch(setPostes(postes));
      const pieces = await roomService.getRooms();
      dispatch(setRooms(pieces));
      const usages = await usageService.getUsages();
      dispatch(setUsages(usages));
      const questions = await questionsIntrumService.getQuestionsIntrums();
      dispatch(setQuestionsInstrums(questions));
      const equipements = await equipementService.getEquipementsBySiteSn(siteSn); /*'86'*/
      dispatch(setEquipementsBySitesSn({ equipements: equipements, siteSn }));    // A TESTER *************
//      const voies2mesures = await voie2mesureService.getVoie2mesures();
//      dispatch(setVoie2mesures(voies2mesures));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectEquipementsState = (state: RootState) => state.equipements;

export const selectEquipementsStateSn = (state: RootState, siteSn: string) => {
  fetchEquipementsBySitesSn(siteSn);
  return state.equipements/*.equipements*/;
}

export const selectEquipementsBySiteId = (state: RootState, siteId: string) => {
  return state.equipements.equipements?.[siteId];
};

export const selectEquipementsBySiteSn = (state: RootState, siteSn: string) => {
//  return state.equipements.equipements?.[siteSn];
// foreach on equipement<array>, return array of equipements ...
  fetchEquipementsBySitesSn(siteSn);    // TEST
  console.log("selectEquipementsBySiteSn equipSlice " + siteSn);
//  fetchEquipementsBySitesSn(siteSn);
//  return state.equipements.equipements[siteSn];     // apparently GOOD
  return state.equipements.equipements?.[siteSn];     // TEST !!!

//  return state.equipements.equipements.setEquipementsBySitesSn(state, siteSn);
  //.gefilterBy((s) => s.sn_site == sn_site);
};

export const selectEquipementById = (
  state: RootState,
  siteId: string,
  equipementId: string
) => {
  return state.equipements.equipements?.[siteId].find((b) => b.id === equipementId);
};

export default equipementsSlice.reducer;
