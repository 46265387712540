import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConcentrateurState, Equipement, EquipementFilterValues, EquipementSortValues, PosteState, QuestionsInstrumState, RoomState, UsageState, Voie2mesure, Voie2mesureState } from '../../redux/types';
import { selectAuthState } from '../../redux/slices/authSlice';
import { deleteEquipement, fetchEquipementsBySitesSn, fetchEquipementsForSiteSn, selectEquipementsState, sortEquipementsBy } from '../../redux/slices/equipementsSlice';
import ConfirmDialog from '../../components/ConfirmDialog';
import { formatDateInWords } from '../../utils/helperFuncs';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { EquipementState } from '../../redux/types';
//import EquipementsMenu from './EquipementsMenu';
import EquipModMenu from '../../components/EquipModMenu';
import EquipementLineInstrum from './EquipementLineInstrum';
import { formatDateTime } from '../../utils/helperFuncs';
import { priorityStyles, statusStyles } from '../../styles/customStyles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Tooltip,
  Paper,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTableStyles } from '../../styles/muiStyles';
import InfoText from '../../components/InfoText';
import { fetchVoie2mesures, selectVoie2mesuresState, sortVoiesBy } from '../../redux/slices/voie2mesuresSlice';
import { useEffect } from 'react';
import filterEquipements from '../../utils/filterEquipements';

const tableHeaders = [
/*  'id',
  'id_poste',
  'id_type_usage',
  'id_piece',
  'reference',
  'id_ref',
  'id_questions',
  'reference',*/
  'Appareil',
//  'Actif',    /* actif = a_suivre : ne pas confondre le suivi qui indique si l'app est encore d'acutalité, et la "a suivre" qui signifie que l'app est suivi ou non-suivi */
  'Poste',
  'Pièce',    /* au survol : préciser la pièce avec str_piece */
  'Début app.',
  'Fin app.',
  //'Date fin',
  'Déb. suivi',
  'Fin suivi',
  'Conc/Mod',
  'Voie',
  /*'str_equip',*/
  'Mesureur',
 /* 'WS',     peu pertinent d'après BR 
  'Actions',*/
];

const EquipementsTable: React.FC<{ 
    siteId: string,
    siteSn: string,
    equipements: EquipementState[], 
    concentrateurs: ConcentrateurState[],
    postes: PosteState[],
    questions: QuestionsInstrumState[],
    pieces: RoomState[],
    voies2mesures: Voie2mesureState[],        // voies de mesures utilisée dans le logement
    usages: UsageState[],
    sortBy: string,
    filterBy: EquipementFilterValues
   }> = ({ siteId, siteSn, equipements, concentrateurs, usages, postes, questions, pieces, voies2mesures, sortBy, filterBy }) => {
  //  console.log("Site SN EquipementTable: "+siteSn);
  const classes = useTableStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { grep } = useSelector(selectEquipementsState);
  console.log("GREP conc filter VALUE = "+grep)
  //const sortStyles = ['default','asc','desc']
  //const sortStyles = [{ 0: '' /*default*/}, { 1: '<ExpandMoreIcon/>' }, { 2: '<ExpandLessIcon/>' }]
  const sortStyles = ['', '<ArrowDropDownIcon/>', '<ArrowDropUpIcon/>']
  const [state,setState] = useState({
    sortAppareil: 0,
    sortPoste: 0,
    sortRoom: 0,
    sortAppStart: 0,
    sortAppEnd: 0,
    sortSuiviStart: 0,
    sortSuiviEnd: 0,
/*    sortPoste: sortStyles[0],
    sortRoom: sortStyles[0],
    sortAppStart: sortStyles[0],
    sortAppEnd: sortStyles[0],
    sortSuiviStart: sortStyles[0],
    sortSuiviEnd: sortStyles[0],*/
   })
  const lesVoie2mesures : Voie2mesureState[] = [];
  equipements.filter((eq) => eq.voies2mesures.length > 0).map((e) => e.voies2mesures.map((v) => 
    lesVoie2mesures.push(voies2mesures.find((vState)=>Number(vState?.id) === v.id)!)));
  const chkedVoie2mesures = lesVoie2mesures.filter((n, i) => lesVoie2mesures.indexOf(n) === i);

  let concMod: { sn: string; modules: number[]; }[] = []
  concentrateurs./*filter((conc)=>conc.date_end===null).*/map((cc) => {
    let modz : number[] = Array.from(cc.modules).sort((a,b) => a > b ? a : b)
    concMod.push({'sn': cc.hardSn, 'modules': modz})
  })
  const sortedConcModz = concMod.sort((a,b) => b.modules.length - a.modules.length);
  sortedConcModz.map((sc) => console.log("sorted conc: "+sc.sn+" / "+sc.modules))
  const SNmodules : number[] = chkedVoie2mesures.map((s)=>s?.id_module);
  const distinctSNmod = SNmodules.filter((n, i) => SNmodules.indexOf(n) === i).sort((a,b) => b - a);
/*  distinctSNmod.map((modSN) => console.log("SN mod: "+modSN))
  concentrateurs.map((concSN) => console.log("SN mod for site_id["+siteId+"]: "+concSN.hardSn))*/
  const seq : number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]
  console.log("NB equipements TOTAL: "+equipements.length)
  //console.log("################ sortByValue: "+sortBy)

  const getNextStyle = (input: number) => {
    if(input === 2) { console.log("newStyle: 0"); return 0 }
    else { console.log("newStyle: "+(input+1)); return input+1 }
  }
  const handleSortChange = (name: string) => {
    if(name === "usage" ) {
      console.log("current "+name+" state: "+state.sortAppareil)
      let style = getNextStyle(state.sortAppareil)
      setState({...state, ['sortPoste']: 0})
      setState({...state, ['sortRoom']: 0})
      setState({...state, ['sortAppStart']: 0})
      setState({...state, ['sortAppEnd']: 0})
      setState({...state, ['sortAppareil']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('usage-a-z'))
        case 2: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('usage-z-a'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "poste" ) {
      console.log("current "+name+" state: "+state.sortPoste)
      let style = getNextStyle(state.sortPoste)
      setState({...state, ['sortAppareil']: 0})
      setState({...state, ['sortRoom']: 0})
      setState({...state, ['sortAppStart']: 0})
      setState({...state, ['sortAppEnd']: 0})
      setState({...state, ['sortPoste']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('poste-a-z'))
        case 2: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('poste-z-a'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "room" ) {
      console.log("current "+name+" state: "+state.sortRoom)
      let style = getNextStyle(state.sortRoom)
      setState({...state, ['sortAppareil']: 0})
      setState({...state, ['sortPoste']: 0})
      setState({...state, ['sortAppStart']: 0})
      setState({...state, ['sortAppEnd']: 0})
      setState({...state, ['sortRoom']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('room-a-z'))
        case 2: return dispatch(sortEquipementsBy('default')) && dispatch(sortEquipementsBy('room-z-a'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "app-start" ) {
      console.log("current "+name+" state: "+state.sortAppStart)
      let style = getNextStyle(state.sortAppStart)
      setState({...state, ['sortAppareil']: 0})
      setState({...state, ['sortPoste']: 0})
      setState({...state, ['sortRoom']: 0})
      setState({...state, ['sortAppEnd']: 0})
      setState({...state, ['sortAppStart']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('newest'))
        case 2: return dispatch(sortEquipementsBy('oldest'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "app-end" ) {
      console.log("current "+name+" state: "+state.sortAppEnd)
      let style = getNextStyle(state.sortAppEnd)
      setState({...state, ['sortAppareil']: 0})
      setState({...state, ['sortPoste']: 0})
      setState({...state, ['sortRoom']: 0})
      setState({...state, ['sortAppStart']: 0})
      setState({...state, ['sortAppEnd']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('app-end-asc'))
        case 2: return dispatch(sortEquipementsBy('app-end-desc'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "suivi-start" ) {
      let style = getNextStyle(state.sortSuiviStart)
      setState({...state, ['sortSuiviStart']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('suivi-start-asc'))
        case 2: return dispatch(sortEquipementsBy('suivi-start-desc'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
    if(name === "suivi-end" ) {
      let style = getNextStyle(state.sortSuiviEnd)
      setState({...state, ['sortSuiviEnd']: style})
      switch(style) {
        case 1: return dispatch(sortEquipementsBy('suivi-end-asc'))
        case 2: return dispatch(sortEquipementsBy('suivi-end-desc'))
        default: return dispatch(sortEquipementsBy('default'))
      }
    }
  };
  const dejaVues: string[] = []
  const numberOnes: number[] = []
  sortedConcModz.map((conc,concNb) => 
    conc.modules.map((modSn,modNb) => {
      console.log("CONC: "+conc.sn+" MODULE: "+modSn)
      seq.map((numero) => {       // inclut une contrainte sur l'ordre des voies, nécessaire à cause des équipements en double suivi
      let find = false
      voies2mesures.filter((v)=>v.id_conc===Number(conc.sn)).sort((a,b)=>a.id_module < b.id_module ? 1 : -1)
        .filter((vm)=>vm.number === 1)
        .flatMap((v)=> {
          if(!dejaVues.includes(String(v.id_conc+v.id_module+v.number))) {
            numberOnes.push(Number(v.id))
            dejaVues.push(String(v.id_conc+v.id_module+v.number)) }
        })
    }
  )}))
/*  sortedConcModz.map((conc,concNb) => 
    conc.modules.map((modSn,modNb) => {
      console.log("CONC: "+conc.sn+" MODULE: "+modSn)
      seq.map((numero) => (       // inclut une contrainte sur l'ordre des voies, nécessaire à cause des équipements en double suivi
      equipements.filter((eq,index)=> eq.voies2mesures.length > 0 && eq.voies2mesures?.filter((vm)=>vm.id_conc===Number(conc.sn) && vm.id_module===modSn && vm.number === 1)?.length > 0)
        .sort((a,b) => a.id > b.id ? 1 : -1).find((eqs)=>eqs.voies2mesures.filter((vv)=>vv.number===1) !== undefined ? 
          numberOnes.push(equipements.filter((eq,index)=> eq.voies2mesures.length > 0 && eq.voies2mesures?.filter((vm)=>vm.id_module===modSn && vm.number === 1)?.length > 0)
            .sort((a,b) => a.id > b.id ? 1 : -1).slice(0)[0].voies2mesures.find((vm)=>vm.number===1)?.id!)
          : null )
      )
    )}))*/
    console.log("ID voies BORDERED: "+numberOnes)
  return (
    <Paper className={classes.table}>
      <Table className={classes.tableEquip}>
        <TableHead>
          <TableRow>
          <TableCell key={'Appareil'} align="center"><Link onClick={() => handleSortChange("usage")}>{'Appareil'}{state.sortAppareil === 1 ? <ArrowDropDownIcon/> : state.sortAppareil === 2 ? <ArrowDropUpIcon/> : null}</Link></TableCell>
          <TableCell key={'Poste'} align="center"><Link onClick={() => handleSortChange("poste")}>{'Poste'}{state.sortPoste === 1 ? <ArrowDropDownIcon/> : state.sortPoste === 2 ? <ArrowDropUpIcon/> : null}</Link></TableCell>
          <TableCell key={'Pièce'} align="center"><Link onClick={() => handleSortChange("room")}>{'Pièce'}{state.sortRoom === 1 ? <ArrowDropDownIcon/> : state.sortRoom === 2 ? <ArrowDropUpIcon/> : null}</Link></TableCell>
          <TableCell key={'Début app.'} align="center"><Link onClick={() => handleSortChange("app-start")}>{'Début app.'}{state.sortAppStart === 1 ? <ArrowDropDownIcon/> : state.sortAppStart === 2 ? <ArrowDropUpIcon/> : null}</Link></TableCell>
          <TableCell key={'Fin app.'} align="center" className={classes.tableEquipBdRight} style={{borderRight: '2px solid rgba(151, 151, 151, 0.2)'}}><Link onClick={() => handleSortChange("app-end")}>{'Fin app.'}{state.sortAppEnd === 1 ? <ArrowDropDownIcon/> : state.sortAppEnd === 2 ? <ArrowDropUpIcon/> : null}</Link></TableCell>
          <TableCell key={'Déb. suivi'} align="center">{'Déb. suivi'}</TableCell>
          <TableCell key={'Fin suivi'} align="center">{'Fin suivi'}</TableCell>
          {/*<TableCell key={'Déb. suivi'} align="center"><Link onClick={() => handleSortChange("suivi-start")}>{'Déb. suivi'}{state.sortSuiviStart === 1 ? <ExpandMoreIcon/> : state.sortSuiviStart === 2 ? <ExpandLessIcon/> : null}</Link></TableCell>
          <TableCell key={'Fin suivi'} align="center"><Link onClick={() => handleSortChange("suivi-end")}>{'Fin suivi'}{state.sortSuiviEnd === 1 ? <ExpandMoreIcon/> : state.sortSuiviEnd === 2 ? <ExpandLessIcon/> : null}</Link></TableCell>*/}
          <TableCell key={'Conc/Mod'} align="center">{'Conc/Mod'}</TableCell>
          <TableCell key={'Voie'} align="center">{'Voie'}</TableCell>
          <TableCell key={'Mesureur'} align="center">{'Mesureur'}</TableCell>
            {/*tableHeaders.map((t, index) => (
              (index === 4) ?
              (<TableCell key={t} align="center" className={classes.tableEquipBdRight} style={{borderRight: '2px solid rgba(151, 151, 151, 0.2)'}}>
                {t}
              </TableCell>)
              : (index === 0) ?
                (<TableCell key={t} align="center">
                  <Link onClick={() => handleSortChange("usage")}>{t}</Link>{sortStyles[state.sortAppareil]}
                </TableCell>)
                : (index > 0) ?
                  (<TableCell key={t} align="center">{t}</TableCell>)
                  : null
            ))*/}
          </TableRow>
        </TableHead>
        <TableBody>
        {/*concentrateurs.sort((a,b) => b.modules.length > a.modules.length ? b.modules.length : a.modules.length).map((conc) => 
            equipements.filter((eq)=> eq.voies2mesures.length > 0 && eq.voies2mesures?.filter((vm)=>vm.id_conc === Number(conc.hardSn)))
            .map((equi) => equi.voies2mesures).map((vv) =>
              vv.map((v) => v.id_module !== 0 ?
            <EquipementLineInstrum equipement={equipements.find((eq) => Number(eq.id) === v.id_equipement)} voieIndex={v.id} 
              voie={v} concentrateurs={concentrateurs} postes={postes} questions={questions} 
              pieces={pieces} voies2mesures={voies2mesures} usages={usages} /> : null
          )))*/}
          {sortBy === "default" ?
            (sortedConcModz.map((conc,concNb) => 
              conc.modules.map((modSn,modNb) => (
              seq.map((numero) => (       // inclut une contrainte sur l'ordre des voies, nécessaire à cause des équipements en double suivi
                equipements.filter((eq,index)=> eq.voies2mesures.length > 0 && eq.voies2mesures?.filter((vm)=>vm.id_module===modSn)?.length > 0 /*&& eq.voies2mesures?.filter((vm)=>vm.id_conc===)*/)
                //.sort((a,b) => a.id > b.id ? 1 : -1)
                .map((equi,eqId) => ( 
                  equi.voies2mesures.filter((d)=>filterBy === 'presentssuivis' ? d.voie_date_end === null : true)
                  .map((v,vindex) =>
                    v.id_conc === Number(conc.sn) && v.id_module === modSn && v.number === numero ?
                      numberOnes.includes(v.id) /*&& equipements[eqId-1]?.voies2mesures!.filter((vm)=>vm.number===1)?.length === 0*/ ?
                        <EquipementLineInstrum equipement={equipements.find((eq) => Number(eq.id) === v.id_equipement)} siteId={siteId} 
                        voie={v} concentrateurs={concentrateurs} postes={postes} questions={questions} 
                        pieces={pieces} voies2mesures={voies2mesures} usages={usages} conc={grep}
                        border={"2px double"}/> 
                      : <EquipementLineInstrum equipement={equipements.find((eq) => Number(eq.id) === v.id_equipement)} siteId={siteId} 
                          voie={v} concentrateurs={concentrateurs} postes={postes} questions={questions} 
                          pieces={pieces} voies2mesures={voies2mesures} usages={usages} conc={grep}
                          border={""}/>
                    : null 
                )
            ))))))))
          : equipements.filter((eq,index)=> eq.voies2mesures.length > 0)
            .map((equi,eqId) => ( 
              equi.voies2mesures.filter((d)=>filterBy === 'presentssuivis' ? d.voie_date_end === null : true)
              .map((v) =>
              <EquipementLineInstrum equipement={equipements.find((eq) => Number(eq.id) === v.id_equipement)} siteId={siteId} 
                voie={v} concentrateurs={concentrateurs} postes={postes} questions={questions} conc={grep}
                pieces={pieces} voies2mesures={voies2mesures} usages={usages} border={""}/>
          )))
            /*equipements/*.sort((a,b) => a.id > b.id ? 1 : -1)/
              .map((equi) => equi.voies2mesures).map((vv) =>
                vv.map((v) =>
              <EquipementLineInstrum equipement={equipements.find((eq) => Number(eq.id) === v.id_equipement)} siteId={siteId} 
                voie={v} concentrateurs={concentrateurs} postes={postes} questions={questions} conc={grep}
                pieces={pieces} voies2mesures={voies2mesures} usages={usages} border={""}/>
          ))*/}
          {equipements.filter((eq)=>eq.voies2mesures.length === 0).map((eq, index) => 
            <EquipementLineInstrum equipement={eq} siteId={siteId} 
              voie={undefined} concentrateurs={concentrateurs} postes={postes} questions={questions} pieces={pieces} conc={grep}
              voies2mesures={voies2mesures} usages={usages} border={ index === 0 ? "3px dashed": "" }/>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EquipementsTable;
