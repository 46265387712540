import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConcentrateurState, Equipement, EquipementFilterValues, PosteState, QuestionsInstrumState, RoomState, UsageState, Voie2mesure, Voie2mesureState } from '../../redux/types';
import { selectAuthState } from '../../redux/slices/authSlice';
import { deleteEquipement, fetchEquipementsBySitesSn, fetchEquipementsForSiteSn } from '../../redux/slices/equipementsSlice';
import ConfirmDialog from '../../components/ConfirmDialog';
import { formatDateInWords } from '../../utils/helperFuncs';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { EquipementState } from '../../redux/types';
//import EquipementsMenu from './EquipementsMenu';
import EquipModMenu from '../../components/EquipModMenu';
import { formatDateTime } from '../../utils/helperFuncs';
import { priorityStyles, statusStyles } from '../../styles/customStyles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Tooltip,
  Paper,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LinkOff from '@material-ui/icons/LinkOffTwoTone';
import { useTableStyles } from '../../styles/muiStyles';
import InfoText from '../../components/InfoText';
import { fetchVoie2mesures, selectVoie2mesuresState } from '../../redux/slices/voie2mesuresSlice';
import { useEffect } from 'react';
import filterEquipements from '../../utils/filterEquipements';

const EquipementLineInstrum: React.FC<{ 
    equipement: EquipementState | undefined, 
    siteId: string,
    concentrateurs: ConcentrateurState[],
    postes: PosteState[],
    questions: QuestionsInstrumState[],
    pieces: RoomState[],
    voie: Voie2mesure | undefined,
    voies2mesures: Voie2mesureState[],
    usages: UsageState[],
    conc: string,
    border: string
   }> = ({ equipement, /*voieIndex,*/siteId, concentrateurs, voie, voies2mesures, postes, pieces, usages, questions, border, conc }) => {
  const classes = useTableStyles();
/*  const history = useHistory();
  const dispatch = useDispatch();*/

  const displayEquipSuivi = (equipement: EquipementState, voie: Voie2mesure, rowColor: string, suiviColor: string, border: string, conc: string) => {
    //const voie = equipement.voies2mesures[voieIndex]
    //console.log("### Voie "+voie.id_module+" "+voie.number+" "+voie.type_sensor+" / "+equipement.str_equip)
    if(conc !== "0") {
        if(voie.id_conc === Number(conc)) {
        return (
        <TableRow key={equipement.id+voie?.id} style={{backgroundColor: rowColor, borderTop: border}}>
            <TableCell align="center" className={classes.clickableCell}>
            <EquipModMenu 
                //TODO: add voie2mesure ID !! pour éditer la bonne voie
                linkName={equipement.usage.name}
                id_voie={voie?.id}
                sn_module={voie?.id_module}
                site_id={siteId}
                sn_site={equipement.sn_site}
                currentData={{
                    id/*_equip*/: equipement.id,
                    id_poste: equipement.poste.id/*.id_poste*/,
                    id_type_usage: equipement.id_type_usage,
                    id_piece: equipement.room?.id/*id_piece*/,
                    reference: equipement.reference,
                    id_ref: equipement.id_ref,
                    reponses: equipement.reponses,
                    id_questions: equipement.id_questions,
                    comments: equipement.comments,
                    a_suivre: equipement.a_suivre,
                    suivi: equipement.suivi,
                    app_date_start: equipement.app_date_start,
                    app_date_end: equipement.app_date_end,
                    sn_site: equipement.sn_site,
                    site_sn: equipement.sn_site,
                    str_piece: equipement.str_piece,
                    str_equip: equipement.str_equip,
                    normalized_pw: equipement.normalized_pw,
                    nrg_class: equipement.nrg_class,
                    water_consumption: equipement.water_consumption,
                    pw_veille: equipement.pw_veille,
                    site_id: siteId,
                    lightsUsageTime: "0",
                    nbLights: "0",
                    powerLights: "0",
                    technoLights: "_",
                    id_circuit: equipement.id_circuit,
                }}
                usages={usages}
                postes={postes}
                pieces={pieces}
                voies2mesures={/*sortedVoies*/voies2mesures}
                questions={questions}
                conc={concentrateurs}
                voieId={voie.id}
                />
            </TableCell>
            <TableCell align="center">
                <span style={{display: 'inline-flex'}}>
                <Tooltip title={<><h3>{equipement.comments}</h3><h3>{"Veille: "+equipement.pw_veille}</h3>
                    <h3>{"PW-normalisé: "+equipement?.normalized_pw}</h3>
                    <h3>{"Class-Énergie: "+equipement.nrg_class}</h3>
                    <h3>{"ConsoEau:"+equipement.water_consumption}</h3></>}>
                    <InfoIcon style={{top: '0.1rem', position: 'relative', 
                    color: equipement.comments.length > 1 ? '#007acc' : 'transparent' }} /> 
                </Tooltip>
                <div style={{paddingLeft: '0.5rem', position: 'relative', top: '0.32rem'}}>
                    {equipement.poste.name}
                </div>
                </span>
            </TableCell>
            <TableCell align="center">
                <Tooltip title={equipement?.str_piece}>
                {/*<Link
                    component={RouterLink}
                    to={`/module/${e?.id_piece}`}
                    color="secondary"
                >*/}
                <span>{equipement.room?.name}</span>
                {/*}  </Link>*/}
                </Tooltip>
            </TableCell>
            <TableCell align="center">
                { equipement.app_date_start !== null ? new Date(equipement.app_date_start).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center" style={{borderRight: '2px solid rgba(151, 151, 151, 0.2)'}}>
                {/*<Tooltip title={e.app_date_end !== null ? new Date(e.app_date_end).toLocaleDateString() : "-"}>*/}
                { equipement.app_date_end !== null ? new Date(equipement.app_date_end).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center" style={{borderLeft: '2px solid rgba(151, 151, 151, 0.2)'}}>
                <div>{voie.voie_date_start !== null ? new Date(voie.voie_date_start).toLocaleDateString() : " ?"}</div>
            </TableCell>
            <TableCell align="center">
                <div><LinkOff style={{top: '0.1rem', position: 'relative', 
                color: voie.voie_date_end !== null ? '#007acc' : 'transparent' }} /> 
                <span style={{bottom: '0.275rem', position: 'relative'}}>{/*new Date(e.app_date_end).toLocaleDateString()*/
                voie.voie_date_end !== null ? new Date(voie.voie_date_end).toLocaleDateString() : "-"
                }</span></div>
            </TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}><span>{voie.id_conc+" / "+voie.id_module}</span></TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}>
                <Tooltip title={<><h3>{voie?.commentaires}</h3><h3>{"Controle: "+voie.freq_controle}</h3><h3>{"Circuit #"+equipement.id_circuit}</h3></>}>
                <InfoIcon style={{top: '0.1rem', position: 'relative', 
                    color: voie.commentaires.length > 0 ? 'black' : 'transparent' }} /> 
                </Tooltip>
                <span style={{bottom: '0.3rem', position: 'relative'}}>&nbsp;{equipement.id_circuit !== "-" && equipement.id_circuit !== null ? voie.number+"/#"+equipement.id_circuit : voie.number}</span>
            </TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}>
            <Tooltip title={"ID: "+voie.id}>
                <span>{voie.id_sensor === null ? voie.type_sensor : voie.type_sensor + " " + voie.id_sensor }</span>
                </Tooltip>
            </TableCell>
        </TableRow>)
        }
        else { return '' }
    } else {
        return (
        <TableRow key={equipement.id+voie?.id} style={{backgroundColor: rowColor, borderTop: border}}>
            <TableCell align="center" className={classes.clickableCell}>
            <EquipModMenu 
                //TODO: add voie2mesure ID !! pour éditer la bonne voie
                linkName={equipement.usage.name}
                id_voie={voie?.id}
                sn_module={voie?.id_module}
                site_id={siteId}
                sn_site={equipement.sn_site}
                currentData={{
                    id/*_equip*/: equipement.id,
                    id_poste: equipement.poste.id/*.id_poste*/,
                    id_type_usage: equipement.id_type_usage,
                    id_piece: equipement.room?.id/*id_piece*/,
                    reference: equipement.reference,
                    id_ref: equipement.id_ref,
                    reponses: equipement.reponses,
                    id_questions: equipement.id_questions,
                    comments: equipement.comments,
                    a_suivre: equipement.a_suivre,
                    suivi: equipement.suivi,
                    app_date_start: equipement.app_date_start,
                    app_date_end: equipement.app_date_end,
                    sn_site: equipement.sn_site,
                    site_sn: equipement.sn_site,
                    str_piece: equipement.str_piece,
                    str_equip: equipement.str_equip,
                    normalized_pw: equipement.normalized_pw,
                    nrg_class: equipement.nrg_class,
                    water_consumption: equipement.water_consumption,
                    pw_veille: equipement.pw_veille,
                    site_id: siteId,
                    lightsUsageTime: "0",
                    nbLights: "0",
                    powerLights: "0",
                    technoLights: "_",
                    id_circuit: equipement.id_circuit,
                }}
                usages={usages}
                postes={postes}
                pieces={pieces}
                voies2mesures={/*sortedVoies*/voies2mesures}
                questions={questions}
                conc={concentrateurs}
                voieId={voie.id}
                />
            </TableCell>
            <TableCell align="center">
                <span style={{display: 'inline-flex'}}>
                <Tooltip title={<><h3>{equipement.comments}</h3><h3>{"Veille: "+equipement.pw_veille}</h3>
                    <h3>{"PW-normalisé: "+equipement?.normalized_pw}</h3>
                    <h3>{"Class-Énergie: "+equipement.nrg_class}</h3>
                    <h3>{"ConsoEau:"+equipement.water_consumption}</h3></>}>
                    <InfoIcon style={{top: '0.1rem', position: 'relative', 
                    color: equipement.comments.length > 1 ? '#007acc' : 'transparent' }} /> 
                </Tooltip>
                <div style={{paddingLeft: '0.5rem', position: 'relative', top: '0.32rem'}}>
                    {equipement.poste.name}
                </div>
                </span>
            </TableCell>
            <TableCell align="center">
                <Tooltip title={equipement?.str_piece}>
                {/*<Link
                    component={RouterLink}
                    to={`/module/${e?.id_piece}`}
                    color="secondary"
                >*/}
                <span>{equipement.room?.name}</span>
                {/*}  </Link>*/}
                </Tooltip>
            </TableCell>
            <TableCell align="center">
                { equipement.app_date_start !== null ? new Date(equipement.app_date_start).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center" style={{borderRight: '2px solid rgba(151, 151, 151, 0.2)'}}>
                {/*<Tooltip title={e.app_date_end !== null ? new Date(e.app_date_end).toLocaleDateString() : "-"}>*/}
                { equipement.app_date_end !== null ? new Date(equipement.app_date_end).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center" style={{borderLeft: '2px solid rgba(151, 151, 151, 0.2)'}}>
                <div>{voie.voie_date_start !== null ? new Date(voie.voie_date_start).toLocaleDateString() : " ?"}</div>
            </TableCell>
            <TableCell align="center">
                <div><LinkOff style={{top: '0.1rem', position: 'relative', 
                color: voie.voie_date_end !== null ? '#007acc' : 'transparent' }} /> 
                <span style={{bottom: '0.275rem', position: 'relative'}}>{/*new Date(e.app_date_end).toLocaleDateString()*/
                voie.voie_date_end !== null ? new Date(voie.voie_date_end).toLocaleDateString() : "-"
                }</span></div>
            </TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}><span>{voie.id_conc+" / "+voie.id_module}</span></TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}>
            <Tooltip title={<><h3>{voie?.commentaires}</h3><h3>{"Controle: "+voie.freq_controle}</h3><h3>{"Circuit #"+equipement.id_circuit}</h3></>}>
                <InfoIcon style={{top: '0.1rem', position: 'relative', 
                    color: voie.commentaires.length > 0 ? 'black' : 'transparent' }} /> 
                </Tooltip>
                <span style={{bottom: '0.3rem', position: 'relative'}}>&nbsp;{equipement.id_circuit !== "-" && equipement.id_circuit !== null && Number(equipement.id_circuit) > 0 ? voie.number+"/#"+equipement.id_circuit : voie.number}</span>
            </TableCell>
            <TableCell align="center" style={{backgroundColor: suiviColor}}>
            <Tooltip title={"ID: "+voie.id}>
                <span>{voie.id_sensor === null ? voie.type_sensor : voie.type_sensor + " " + voie.id_sensor }</span>
                </Tooltip>
            </TableCell>
        </TableRow>)
    }
    };
    const displayNonSuivi = (e: EquipementState, border: string) => {
        return(
        <TableRow key={e.id} style={{ borderTop: border }}>
            <TableCell align="center" className={classes.clickableCell} >
                <EquipModMenu
                linkName={e.usage.name}
                id_voie={1}
                sn_module={1}
                site_id={siteId}
                sn_site={e.sn_site}
                currentData={{
                    id: e.id,
                    id_poste: e.poste.id,
                    id_type_usage: e.id_type_usage,
                    id_piece: e.room?.id,
                    reference: e.reference,
                    id_ref: e.id_ref,
                    reponses: e.reponses,
                    id_questions: e.id_questions,
                    comments: e.comments,
                    a_suivre: e.a_suivre,
                    suivi: e.suivi,
                    app_date_start: e.app_date_start,
                    app_date_end: e.app_date_end,
                    site_sn: e.sn_site,
                    sn_site: e.sn_site,
                    str_piece: e.str_piece,
                    str_equip: e.str_equip,
                    site_id: siteId,
                    nrg_class: e.nrg_class,
                    normalized_pw: e.normalized_pw,
                    water_consumption: e.water_consumption,
                    pw_veille: e.pw_veille,
                    lightsUsageTime: "0",
                    nbLights: "0",
                    powerLights: "0",
                    technoLights: "_",
                    id_circuit: e.id_circuit,
                }}
                usages={usages}
                postes={postes}
                pieces={pieces}
                voies2mesures={/*sortedVoies*/voies2mesures}
                questions={questions}
                conc={concentrateurs}
                voieId={0}
                />
            </TableCell>
            <TableCell align="center">
                <span style={{display: 'inline-flex'}}>
                <Tooltip title={<><h3>{e?.comments}</h3><h3>{"Veille: "+e.pw_veille}</h3><h3>{"PW-normalisé: "+e?.normalized_pw}</h3><h3>{"Class-Énergie: "+e.nrg_class}</h3><h3>{"ConsoEau:"+e.water_consumption}</h3></>}>
                    <InfoIcon style={{top: '0.1rem', position: 'relative', 
                    color: e.comments.length > 3 ? '#007acc' : 'transparent' }} /> 
                </Tooltip>
                <div style={{paddingLeft: '0.5rem', position: 'relative', top: '0.32rem'}}>
                    {e.poste.name}
                </div>
                </span>
            </TableCell>
            <TableCell align="center">
                <Tooltip title={e?.str_piece}>
                <span>{e.room?.name}</span>
                </Tooltip>
            </TableCell>
            <TableCell align="center">
                { e.app_date_start !== null ? new Date(e.app_date_start).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center" style={{borderRight: '2px solid rgba(151, 151, 151, 0.2)'}}>
                { e.app_date_end !== null ? new Date(e.app_date_end).toLocaleDateString() : "-" }
            </TableCell>
            <TableCell align="center">
                <div>-</div>
            </TableCell>
            <TableCell align="center">
                <div>-</div>
            </TableCell>
            <TableCell align="center">
                {"Non suivi"}
            </TableCell>
            <TableCell align="center">{"-"}</TableCell>
            <TableCell align="center">{"-"}</TableCell>
        </TableRow>)
    };
    if(equipement !== undefined /*&& voie !== undefined*/) {
        if(/*equipement?.voies2mesures.length === 0*/ voie === undefined) {
            return displayNonSuivi(equipement,border)
        } else { 
            let rowColor=""
            let suiviColor=""
            let cptVoiesActives=0
            let cptVoies=0
            equipement.voies2mesures.filter((v)=>v.id_conc === voie.id_conc).map((vm) => {
                if(vm.voie_date_end?.toString().length === undefined) { cptVoiesActives++ }
                cptVoies++
                //console.log("++++++++++++++STR-Date: "+vm.voie_date_end?.toString())
            })
            //console.log("++++++++++++++ cptVoiesActives: "+cptVoiesActives)
            if(cptVoiesActives > 1) {
                rowColor="rgba(151, 151, 151, 0.2" 
            } else { rowColor="" }
            if(cptVoies > 1 && cptVoiesActives === 0) {
                suiviColor="rgba(110, 110, 110, 0.2" 
            } else { suiviColor="" }
            return displayEquipSuivi(equipement, voie, rowColor, suiviColor, border, conc)
        }
    } else { return (<TableRow></TableRow>) };
};

export default EquipementLineInstrum;
