import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { EquipementPayload, ReponsesPayload } from '../redux/types';

const baseUrl = `${backendUrl}/sites`;

const addEquipements = async (siteId: string, equipements: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/equipements`,
    { equipements },
    setConfig()
  );
  return response.data;
};

const getAllEquipements = async () => {
// const response = await axios.get(`${baseUrl}/${siteId}/equipements`, setConfig());
  const response = await axios.get(`${backendUrl}/allequipements`, setConfig());
  return response.data;
};

const getEquipementsBySiteSn = async (siteSn: string) => {
//  const response = await axios.get(`${backendUrl}/site/${siteSn}/equipements`, setConfig());
  const response = await axios.get(`${backendUrl}/equipements/${siteSn}/equipements`, setConfig());
  console.log("service: getEquipementsBySiteSn "+siteSn);
  return response.data;
};

const createEquipement = async (siteId: string, equipementData: EquipementPayload) => {
  const response = await axios.post(
    `${backendUrl}/equipements/create/${siteId}`,
//    `${baseUrl}/${siteId}/equipements`,
    equipementData,
    setConfig()
  );
  return response.data;
};

const updateReponsesFI = async (
  equipementId: string,
  repstr: ReponsesPayload
) => {
  const response = await axios.put(
//    const response = await axios.post(
      `${backendUrl}/equipements/updatereponses/${equipementId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    repstr/*"toto"*//*"repstr:"+repstr*/,
    setConfig()
  );
  return response.data;
};

const updateEquipementDB = async (
  equipementId: string,
  equipementData: EquipementPayload
) => {
  const response = await axios.put(
    `${backendUrl}/equipements/updatedb/${equipementId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    equipementData,
    setConfig()
  );
  return response.data;
};

const updateEquipement = async (
//  siteId: string,
  equipementId: string,
  equipementData: EquipementPayload
) => {
  const response = await axios.put(
    `${backendUrl}/equipements/update/${equipementId}`,
    equipementData,
    setConfig()
  );
//  console.log("RESPONSE: "+response.data[0])
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
//  console.log("SN SITE response Axios: "+response.data.map((e: EquipementPayload) => e.sn_site));
  return response.data;
};

const deleteEquipementDB = async (siteId: string, equipementId: string, voieId: string) => {
  const response = await axios.delete(
    `${backendUrl}/equipements/${siteId}/delete4ever/${equipementId}/${voieId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    setConfig()
  );
  return response.data;
};

const deleteEquipement = async (siteId: string, equipementId: string) => {
  const response = await axios.delete(
    `${backendUrl}/equipements/${siteId}/delete/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    setConfig()
  );
  return response.data;
};

const closeEquipement = async (siteId: string, equipementId: string) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/equipements/${equipementId}/close`,
    null,
    setConfig()
  );
  return response.data;
};

const reopenEquipement = async (siteId: string, equipementId: string) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/equipements/${equipementId}/reopen`,
    null,
    setConfig()
  );
  return response.data;
};

const equipementService = {
  addEquipements,
  getAllEquipements,
  getEquipementsBySiteSn,
  createEquipement,
  updateEquipement,
  updateReponsesFI,
  updateEquipementDB,
  deleteEquipement,
  deleteEquipementDB,
  closeEquipement,
  reopenEquipement,
};

export default equipementService;
